import { isOpenListDictVar } from "./vars/dictionary";

export default {
  typePolicies: {
    Query: {
      fields: {
        isOpenListDict: {
          read() {
            return isOpenListDictVar()
          }
        }
      }
    }
  }
}
